html, body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #ffffff;
}