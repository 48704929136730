.root {
  width: 100%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
}

.root > div {
  width: 100%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible!important;
}


.root > div > div:nth-child(2) {
  width: 100%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible!important;
  background-color: #ffffff;
}